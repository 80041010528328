<template>
    <section class="intellectual-property py-5 d-flex flex-column flex-lg-row">
        <div class="d-flex justify-content-center col-12 col-lg-6">
            <img
                class="intellectual-property__img"
                src="../../assets/views/project_expertise/ii-img.svg"
                alt=""
            >
        </div>

        <div class="intellectual-property-block col-12 col-lg-6 ms-2 ms-lg-0 mt-4 mt-lg-0 align-self-center ps-2">
            <div class="intellectual-property__title">{{ getIntellectualPageInfo('title') }}</div>
            <div class="intellectual-property__text mt-4">
                {{ getIntellectualPageInfo('text') }}
            </div>

            <ul class="p-0 mt-3">
                <li
                    v-for="(point, index) in getPoints()"
                    :key="index"
                    class="d-flex align-items-center mt-3"
                >
                    <img src="../../assets/views/technopark/check.svg" alt="">
                    <div
                        class="ms-3 accelerator-programs__text"
                        v-html="point"
                    >
                    </div>
                </li>
            </ul>
        </div>
    </section>

    <div class="ip-exchange__useful-things mt-5 col-12 col-lg-10 mx-auto">
        <div class="technopark-numbers__title mb-4">Полезные материалы</div>

        <div class="material-card p-4 d-flex flex-column flex-xl-row w-100">
            <div class="material-card__text">1. Стратегия правовой охраны изобретений</div>
            <div class="mt-2 mt-xl-0 ms-xl-auto">
                <a href="https://tt.itmo.ru/media/intellectual_property/Стратегия_правовой_охраны_изобретений_20.01 корр.pdf" target="_blank"
                    class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
            </div>
        </div>

        <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
            <div class="material-card__text">2. Стратегия правовой охраны полезных моделей</div>
            <div class="mt-2 mt-xl-0 ms-xl-auto">
                <a href="https://tt.itmo.ru/media/intellectual_property/Стратегия_правовой_охраны_полезных_моделей_20.01 корр.pdf" target="_blank"
                    class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
            </div>
        </div>
        <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
            <div class="material-card__text">3. Стратегия правовой охраны промышленных образцов</div>
            <div class="mt-2 mt-xl-0 ms-xl-auto">
                <a href="https://tt.itmo.ru/media/intellectual_property/Стратегия_правовой_охраны_промышленных_образцов_20.01 корр.pdf" target="_blank"
                    class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
            </div>
        </div>
        <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
            <div class="material-card__text">4. Стратегия правовой охраны секретов производства</div>
            <div class="mt-2 mt-xl-0 ms-xl-auto">
                <a href="https://tt.itmo.ru/media/intellectual_property/Стратегия_правовой_охраны_секретов_производства_(3)_20.01 корр.pdf" target="_blank"
                    class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
            </div>
        </div>
        <div class="material-card p-4 d-flex flex-column flex-xl-row w-100 mt-2">
            <div class="material-card__text">5. Стратегия правовой охраны ЭВМ и БД</div>
            <div class="mt-2 mt-xl-0 ms-xl-auto">
                <a href="https://tt.itmo.ru/media/intellectual_property/Стратегия_правовой_охраны_ЭВМ_и_БД_20.01 корр.pdf" target="_blank"
                    class="material-card__link d-inline-block me-3">Перейти к материалам</a>
                <img src="../../assets/views/ip-exchange/arrow-right.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex";
import {computed} from "vue";

export default {
    name: "IntellectualProperty",

    setup() {
        const store = useStore()

        store.dispatch('pages/getProjectsExpertisePage')
        const projectsExpertisePage = computed(() => store.getters['pages/projectsExpertisePage'])

        const getPoints = () => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.third_tab.points_split
            }

            return []
        }

        const getIntellectualPageInfo = (key) => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.third_tab[key]
            }

            return ''
        }

        return {
            getPoints,
            getIntellectualPageInfo
        }
    }
}
</script>
